import { Col, Container, Image, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import styles from "./styles/Character.module.css";
import getById from "./people.js";
import HTMLReactParser from "html-react-parser";

export default function Profile() {
  const [pg, setPg] = useState(false);
  const [birthday, setBirthday] = useState(new Date());

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let id = params.get("id");
    const profile = getById(id);
    setPg(profile);
    setBirthday(
      new Date(
        Math.random() * (2060 - 2020) + 2020,
        0,
        Math.floor(Math.random() * 365)
      )
    );
  }, []);

  const border_classes = "border border-1 border-dark";
  return (
    <>
      {pg && (
        <Container>
          <Row>
            <Col xs="auto" className={`align-items-center`}>
              <h2>Scheda personaggio</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={4} className={border_classes}>
              <Row>
                <small>Name:</small>
              </Row>
              <Row>
                <span>{`${pg.firstname} ${pg.lastname}`}</span>
              </Row>
            </Col>
            <Col xs={4} className={border_classes}>
              <Row>
                <small>character:</small>
              </Row>
              <Row>
                <span>{`${pg.character}`}</span>
              </Row>
            </Col>
            <Col xs={4} className={border_classes}>
              <Row>
                <small>Birthday:</small>
              </Row>
              <Row>
                <span
                  style={{ textDecoration: "line-through 10px" }}
                >{`${birthday.getDate()}/${birthday
                  .getMonth()
                  .toString()
                  .padStart(2, 0)}/${birthday.getFullYear()}`}</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6} lg={3} className={border_classes}>
              <div
                className={"my-5"}
                style={{
                  background: `url("${pg.image}") 0 0 no-repeat`,
                  backgroundSize: "cover",
                  height: "350px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#f5d4a873",
                    zIndex: 3,
                    height: "100%",
                  }}
                ></div>
              </div>
            </Col>
            <Col xs={6} lg={9} className={border_classes}>
              <div style={{ height: "100%", width: "100%" }}>
                <Image
                  alt="classified"
                  src="/images/classified.png"
                  width="361"
                  height="107"
                  className={styles.classified}
                ></Image>
                <h3>Background</h3>

                <p>{HTMLReactParser(pg.description)}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={border_classes}>
              <h2 className="text-center">
                DEADLY WEAPON: <strong>{pg.deadlyWeapon}</strong>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={border_classes}>
                <h3 className="text-center">Sub Quests</h3>
                {pg.subQuests.map((event, index) => (
                  <Row key={index} className="align-items-center">
                    <Col xs={10}>- {HTMLReactParser(event)}</Col>
                  </Row>
                ))}
            </Col>
          </Row>
        </Container>
      )}
      {!pg && <h1>Ci hai provato :D</h1>}
    </>
  );
}

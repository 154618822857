import _ from "lodash";

const people = [
  {
    id: "U2FsdGVkX1+C23dggNjEkqjsW6KR+KB1rjeuPO96LO93CagdL/V1rCOc7vgE6o8y",
    firstname: "Samuele",
    lastname: "Angeletti",
    image: "/images/players/samuele_angeletti.jpg",
    character: "Investitore",
    description: "",
    triggers: [],
    deadlyWeapon: "NONE",
    subQuests: [],
  },
  {
    id: "U2FsdGVkX19ZSbd5pj2z55CwjvFOjJSsDXpI0IbA4Ikdwk3Q4SeEdf+KnDQsOD9D",
    firstname: "Marta",
    lastname: "Baldini",
    image: "/images/players/not_found_girl.jpg",
    character: "Recupero Crediti",
    description:
      "Dipendente di Equitalia, arrivata alla festa perchè da mesi da la caccia al <strong>presidente</strong> poichè si sospetta che abbia dei problemi con il fisco, è inoltre convinta che alla festa sia presente un <strong>avvocato</strong> pronto a difendere il presidente da accuse di questo tipo. Gira sempre con un fascicolo con su scritto 'cartella esattoriale'",
    deadlyWeapon: "Banconote",
    subQuests: [
      "Chiedere ad almeno 3 partecipanti con insistenza se hanno pagato le tasse",
      "Parlare di quanto sia meglio oggi che si paga solo col bancomat, rispetto al passato",
      "Quando tocca un tovagliolo starnutisce perchè gli ricorda le banconote a cui è allergica",
    ],
  },
  {
    id: "U2FsdGVkX19WktiVTgAOA9qcmdMp1wA4Htax/UuXRfNBqfJfFXhCHYgfceyL08XP",
    firstname: "Jacopo",
    lastname: "Bargiacchi",
    image: "/images/players/not_found_boy.jpg",
    character: "Presidente",
    description:
      "Genio, Miliardario, Playboy, Filantropo. Capo dell'azienda da molto tempo e e leader illuminato, ha fatto raggiungere i massimi successi e se ne vanta con piacere, non può toccare il Trascendentatore perchè allergico allo Stronzio, materiale di cui è principalmente composto. Negli ultimi anni ha conosciuto una figura controversa che ha dimostrato di conoscere il futuro e aiutato nelle scelte finanziare, non sempre pulite, oggi è il suo <strong>capo della sicurezza</strong>",
    deadlyWeapon: "Stronzio (SR)",
    subQuests: [
      "richiamare l'attenzione di tutti, fingendo una conferenza stampa in cui dichiara di essere Iron Man",
      "Ogni volta che deve toccare il Trascendentatore deve indossare dei guanti",
      "Quando muore qualcuno, deve confrontarsi col <strong>capo della sicurezza</strong>",
    ],
  },
  {
    id: "U2FsdGVkX1+Aj3rzmqeix2373A88qZPC2SVuxGgb6dGhOZzb9feLObz9P1MWg+a+",
    firstname: "Eleonora",
    lastname: "Calamai",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/02/IMG_3400-1-600x800.jpg",
    character:
      "Neuroscienziata",
    description:
      "Insieme al <strong>chimico</strong>, <strong>fisico</strong> e il <strong>medico</strong> hanno creato il core del Trascendentatore, c'è una forte lite tra loro poichè ognuno ritiene di aver dato un contributo maggiore alla creazione del prodotto. Da piccola è rimasta coinvolta in un'incidente con un camion di Nesquik e d'allora è allergica alle cose dolci",
    deadlyWeapon: "Pasticcini",
    subQuests: [
      "chiedere conferma per ogni pietanza che mangia per la prima volta se è principalmente dolce",
      "offendere i colleghi, millantando cose fatte da te sulla creazione del Trascendetatore",
      "in caso di omicidio attivare il Trascendentatore sulla vittima",
    ],
  },
  {
    id: "U2FsdGVkX19aDxEwczR4/T1aEvqPRc4jTE2LlgqW4Z0WqPZSo4d6czD2zV/YOTRc",
    firstname: "Alessio",
    lastname: "Chen",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/ALES-600x800.png",
    character: "Negazionista",
    description:
      "Si è laureato in Sciechimicologia all'università della vita, beve solo l'acqua dalla sua borraccia magica che filtra le sostanze nocive, non crede alle possibilità del Trascendentatore. Ha partecipato alla mappatura del suo cervello in cambio di azioni dell'azienda solo per dimostrare che non funziona.",
    deadlyWeapon: "Acqua",
    subQuests: [
      "Parlare dei filtri magici della sua borraccia",
      "in caso di omicidio ripetere a chi userà il Trascendentatore che è tutto inutile",
      "Riconosci e convinci il <strong>Designer Visionario</strong> delle tue pazze teorie di complotto",
    ],
  },
  {
    id: "U2FsdGVkX18er10K3Zhpf9vL3zpF0DV1iiJHxTAv1Y0ZHPvK6RyjIzzxEzewGyRB",
    firstname: "Alessio",
    lastname: "Colzi",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/02/ale-600x800.jpg",
    character: "Detective",
    description:
      "Invitato alla festa dagli investitori che preoccupati per la situazione temono il peggio. Se stato pagato con una parte delle azioni dell'azienda, per il resto cerchi di tenerti in disparte per studiare la situazione e cercare di capire quante più cose possibili. Nessuno sa chi sei e devi cercare di sviare quanto più possibile sulla tua presenza lì finchè non avviene almeno un omicidio.",
    deadlyWeapon: "None",
    subQuests: [
      "Interrogare una persona puntadogli un flash in faccia in stile americano",
      "ad ogni omicidio prendere le redini della situazione",
      "mostrare il tuo distintivo ai presenti",
    ],
  },
  {
    id: "U2FsdGVkX1/v2TK0cwPtFojDS2wOlejg3B/a/OQR836orPF68wnFe4FVTjuqm/vg",
    firstname: "Alessandro",
    lastname: "Di Vincenzo",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/04/akmsd-600x800.jpg",
    character: "Sviluppatore IA/Supereroe",
    description:
      "Sei arrivato sulla Terra da neonato da un'astronave e sei stato adottato dai terrestri, sin da piccolo hai mostrato una forza inaudita che però veniva meno se a contatto con la tua cryptonite: i sassi. Oggi sei uno sviluppatore di giorno, superoe di notte. Hai aiutato la creazione della IA del Trascendentatore, ma il suo sesto senso gli fa credere che stasera succederà qualcosa.",
    deadlyWeapon: "Sasso",
    subQuests: [
      "dopo un omicidio, sparisci e travestiti da superore (ovviamente il nome è a tua discrezione) pronto a risolvere la situazione",
      "come alieno clandestino, cerca di evitare il <strong>Men in Black</strong> che ti potrebbe dare la caccia",
      "Se cammini sui sassi diventa fiacco",
    ],
  },
  {
    id: "U2FsdGVkX1+vmJYN427NXAiOtjwHxUPLQ2PTaFT3145NcP4i3vgMWGGAYNBg3mmX",
    firstname: "Antonio",
    lastname: "Facciuti",
    image: "",
    character: "Investitore 2",
    description: "",
    deadlyWeapon: "None",
    subQuests: [],
  },
  {
    id: "U2FsdGVkX1/tk7wYtcukITtGXqFb8DmPI1e8FaQGHcI+/xHcCrrxm2avpCeYh1mQ",
    firstname: "Giovanni",
    lastname: "Iandelli",
    image: "/images/players/not_found_boy.jpg",
    character: "Rockstar",
    description:
      "Figlio del capo ingegnere di Commit Global, rimasto ucciso recentemente da un incidente elettrico durante lo sviluppo del Trascendentatore (chissà che non sia stato un incidente), nella vita sei diventato famoso come giovane rockstar controversa, vivendo di sesso, droga e rock'n'roll, hai un abbigliamento e comportamento sempre eccentrico",
    deadlyWeapon: "Corrente Elettrica",
    subQuests: [
      "offenditi se chiedono chi sei, perchè come non possono non riconoscere Damian... Giovanni la rock star (il nome della band a tua discrezione)",
      "Canta o suona o trasmetti la tua ultima hit",
      "tieni un discorso sul tragico incidente di tuo padre non tralasciando i dettagli sul come",
    ],
  },
  {
    id: "U2FsdGVkX1+s3rx/EhkSS9FZsl6tOACLegRjYpARv/FGBUi817jJoRdCe0TiqGqR",
    firstname: "Mamutaj",
    lastname: "Xhesjona",
    image: "/images/players/not_found_girl.jpg",
    character: "Avvocato",
    description:
      "Dopo aver nascosto le prove sul presunto omicidio del padre della <strong>Rockstar</strong>, si sta occupando di nascondere i soldi a nero del <strong>presidente</strong>. Non può girare senza le sue mazzette che solitamente da a chiunque prova a chiedere delle sue finanze",
    deadlyWeapon: "Cartella Esattoriale",
    subQuests: [
      "Se qualcuno ti chiede informazioni sull'azienda, tu prova a corromperlo con delle banconote per zittirlo",
      "avvicinati al <strong>presidente</strong> ogni volta che prova ad avvicinarsi anche il <strong>recupero crediti</strong>",
      "Vantati delle tue cause vinte inventate per l'occasione",
    ],
  },
  {
    id: "U2FsdGVkX19A6CV9yZpF53D4KdZRYYO4XRGctjXBePNC5LhKyUQdIj4RQ+mSFGJR",
    firstname: "Gerta",
    lastname: "Menga",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/GERT-600x800.png",
    character: "Ethical Hacker",
    description:
      "Dopo un passato ad hackerare tutti i sistemi del mondo, ha deciso di unirsi a Commit Global come Ethical Hacker per proteggere l'azienda da attacchi futuri. Crede ciecamente al Trascendentatore quasi come se fosse una religione perchè spera di vivere in eterno e mostrare la propria grandezza entrando nel Matrix, come il suo protagonista preferito del suo film preferito, da cui copia anche l'abbigliamento. Ha un piccolo processore che la rende più intelligente, ma  che è vulnerabile ai Trojan",
    deadlyWeapon: "Virus",
    subQuests: [
      "Schivare ostacoli invisibili durante la serata alla matrix",
      "Indovinare usando ChatGPT chi tra i presenti è l'<strong>hacker</strong> che la minaccia coi virus",
      "Parlare della sua intelligenza superiore ottenuta grazie al processore Intel che ha addoso",
    ],
  },
  {
    id: "U2FsdGVkX18XpsL+PbEWjpw+tele1t1iUEA55DdJnb3EtAzL6gtpYTGAEwKmS4rP",
    firstname: "Sebastiano",
    lastname: "Picciau",
    image: "/images/players/not_found_boy.jpg",
    character: "Men in Black",
    description:
      "Sebastiano per gli amici, ma il tuo vero è Agente S, ti sei infiltrato in Commit Global come programmatore perchè sei alla ricerca di un <strong>superoe</strong> alieno che si aggira tra noi. Ovviamente sei armato di neuralizzatore col quale puoi convincere una persona per volta a svolgere un'azione per conto tuo, uso singolo per personaggio",
    deadlyWeapon: "Flash",
    subQuests: [
      "usa il neuralizzatore sul <strong>detective</strong>",
      "usa il tuo contatore Gaiger per scoprire chi è l'alieno",
      "indossa sempre gli occhiali da sole e se ti chiedono di toglierli dì che sei infastidito dalla luce",
    ],
  },
  {
    id: "U2FsdGVkX19hV9W4NuYsE7jet/j9DtME85YyaoMRI71J8lMfB/KYR1d+WZ6kh7n+",
    firstname: "Simone",
    lastname: "Sarracino",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/SIMO-1-600x800.png",
    character: "Designer Visionario",
    description:
      "Mente brillante rubata alla Fruit Salad inc (ex Apple, ha cambiato nome per essere più inclusiva). Personaggio eccentrico e sopra le righe che ha progettato il design del Trascendentatore: il cui prototipo è Skolapsta ispiratosi ai designer ikea. è convinto che ascoltare qualsiasi cosa possa essere fonte di ispirazione per le sue creazioni future quindi tende ad assecondare qualsiasi ciarlateno che gli parla. Visti i suoi anni passati alla Apple ha sviluppato un odio talmente forte con la Microsoft da odiare addirittura le finestre (o qualche acido lo ha indotto a questa fobia, solo un esperto saprebbe rispondere)",
    deadlyWeapon: "Finestra",
    subQuests: [
      "Convincere il <strong>Presidente</strong> che forse il <strong>negazionista</strong> non ha tutti i torti e può essere fonte di ispirazione per l'azienda",
      "componi l'arredamento con fare Shakespiriano e lasciati ispirare dalla tua creazione",
      "se vicino a una finestra accassciati in preda a una strana convulzione",
    ],
  },
  {
    id: "U2FsdGVkX1/1B4qrLmuC/aSspKFtlwecIi3OUSxtv6HmPsuewTuRNnkwYb7WeZTy",
    firstname: "Sebastian",
    lastname: "Scimè",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/SEB-600x800.png",
    character: "Hacker",
    description:
      "Ti sei unito alla serata dopo aver hackerato i conti di un azionista estero e ti sei appropriato delle sue quote azionarie, solamente per distruggere il Trascendentatore, perchè la sua IA unita alla mappatura mentale delle persone potrebbe mandare al lastrico molti lavori del mondo digitale. In guerra da anni contro ChatGPT che ormai ha conquistato metà del globo",
    deadlyWeapon: "ChatGPT",
    subQuests: [
      "scopri con i tuoi Virus chi è l'<strong>Ethical Hacker</strong> prima che lo faccia lui con te",
      "avvicinati al Trascendentatore e cerca di manometterlo col tuo cellulare",
      "cerca un modo di cambiare la musica per distrarre gli invitati",
    ],
  },
  {
    id: "U2FsdGVkX19pzEd1sduywf1J0uIvbH/M3aXdXAm2Pzq4Qh/8N/8SVmALaRiUmjiv",
    firstname: "Sibillla",
    lastname: "Simoncini",
    image: "/images/players/not_found_girl.jpg",
    character: "Chimico",
    description:
      "Insieme al <strong>neuroscienziato</strong>, <strong>fisico</strong> e il <strong>medico</strong> hanno creato il core del Trascendentatore, c'è una forte lite tra loro poichè ognuno ritiene di aver dato un contributo maggiore alla creazione del prodotto. è ossessionata dalla pulizia personale perchè a causa dei nuovi vaccini la pelle è diventata estremamente sensibile al sapone comune, quindi ha creato un gel in stile Amuchina solo per uso personale, che porta sempre con se.",
    deadlyWeapon: "Sapone",
    subQuests: [
      "offendere i colleghi, millantando cose fatte da te sulla creazione del Trascendetatore",
      "Igienizzarsi le mani ogni volta possibile, facendo notare come il suo gel sia più efficiente di altri metodi",
      "Parlare male dei vaccini col <strong>negazionista</strong>",
    ],
  },
  {
    id: "U2FsdGVkX18Dem6W2WM15CcmujGbnZQqa/b+dDTIDcqNkFYirJtuGd9Cm5jyLa+8",
    firstname: "Andrei",
    lastname: "Sorescu",
    image: "/images/players/not_found_boy.jpg",
    character: "Capo della sicurezza",
    description:
      "Arriva da un futuro catastrofico, da uno studio condotto dalla sua generazione è emerso che il Trascendentatore è la iniziale causa della catastrofe che si abbatte attualmente ed è tornato indietro per cercare di fermare tutto ciò. Si è avvicinato molto al <strong>presidente</strong> di Commit Global aiutandolo a fare successo con affari più o meno loschi. si è fatto nominare <strong>capo della sicurezza</strong> per restare vicino al Trascendentatore e evitare che cada nelle mani sbagliate",
    deadlyWeapon: "Bilanciere",
    subQuests: [
      "Resta vicino al Trascendentatore e ammonisci chiunque provi ad avvicinarsi senza un reale motivo",
      "metti in mostra i tuoi bicipiti di marmo a tutti",
      "quando avviene un omicidio, avvicinati al <strong>presidente</strong> per convincerlo che questo era l'unico modo per evitare la catastrofe (perchè ovviamente tu sai già come andrà in futuro)",
    ],
  },
  {
    id: "U2FsdGVkX1/JjnWc4mSnD9yAsAUEKRnLNgG0dtOZxSYKPedqMBrt2u2g42NTp+dr",
    firstname: "Ionut",
    lastname: "Tanasa",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/IONU-600x800.png",
    character: "Fisico",
    description:
      "Insieme al <strong>chimico</strong>, <strong>neuroscenziato</strong> e il <strong>medico</strong> hanno creato il core del Trascendentatore, c'è una forte lite tra loro poichè ognuno ritiene di aver dato un contributo maggiore alla creazione del prodotto. è diventato allergico al pesce dopo una settimana passata in un all you can eat per una sfida con i colleghi. Oggi può mangiarlo purchè non lo tocchi con la pelle, per questo ha inventato il Guanto Con Bacchetta® (un guanto di lattice con bacchette che fuoriescono dal pollice e dall'indice, va fortissimo in Giappone)",
    deadlyWeapon: "Pesce",
    subQuests: [
      "offendere i colleghi, millantando cose fatte da te sulla creazione del Trascendetatore",
      "mostrare a tutti la sua ultima invenzione (ovviamente il Guanto Con Bacchetta®)",
      "avere un crampo allo stomaco a causa di una cena a base di sushi della sera prima",
    ],
  },
  {
    id: "U2FsdGVkX18VT0mT4SPpBVRjLJ/hDPsNMKZL4oBgczQZ8yKLx+MfhGh4HzUR0yW1",
    firstname: "Gianmarco",
    lastname: "Tarparelli",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/GIANM-600x800.png",
    character: "Medico",
    description:
      "Insieme al <strong>chimico</strong>, <strong>fisico</strong> e il <strong>neuroscenziato</strong> hanno creato il core del Trascendentatore, c'è una forte lite tra loro poichè ognuno ritiene di aver dato un contributo maggiore alla creazione del prodotto. Hai una folle e irrazionale paura del sangue, ma grazie alle nuove scoperte mediche è finalmente possibile conoscere e operare il corpo umano senza la parte schifosa del sangue e viscere annesse, questo ti ha spinto a diventare medico, ma per ulteriore sicurezza ti sei unito a Commit Global per non dover lavorare in ospedale.",
    deadlyWeapon: "Sangue",
    subQuests: [
      "offendere i colleghi, millantando cose fatte da te sulla creazione del Trascendetatore",
      "ogni volta che qualcuno sta male o muore devi intervenire, ma prima chiedi se è uscito del sangue",
      "dcondividi le tue pasticche al <strong>designer visionario</strong> per incentivare la sua creatività"
    ],
  },
  {
    id: "U2FsdGVkX1/kKw09mtMwopw0p4HHGRT6Qo+1b2UJwxAea5QMAIPxJA38iCxSOlvK",
    firstname: "Terzo",
    lastname: "Claudio",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/CLAU-600x800.png",
    character: "Cliente",
    description: "Miliardario arrivato al successo passando anche per vie non legali che ha finanziato Commit Global, oggi vorrebbe acquistare il brevetto del Trascendentatore per selezionare le menti da mappare e creare una sua società utopistica digitale. Sarebbe disposto a uccidere pur di avere conferma del funzionamento del Trascendentatore ",
    deadlyWeapon: "Funghi",
    subQuests: [
      "Cerca di uccidere un giocatore o convinci qualcuno a uccidere per te per vedere in azione il Trascendentatore",
      "Emozionati quando vedi in azione il Trascendentatore per la prima volta",
      "chiedere conferma per ogni pietanza che mangia per la prima volta se sono presenti funghi",

    ],
  },
  {
    id: "U2FsdGVkX18XJQXH2DfPA0drfmm9sIoaN5UDUjSIFOqUnnYJmp2371u5GXKLodg3",
    firstname: "Daniele",
    lastname: "Tortelli",
    image: "https://www.commitsoftware.it/wp-content/uploads/2023/01/DANI-600x800.png",
    character: "Capo spionaggio aziendale",
    description: "Un uomo avvolto dall'oscurità, la sua copertura è lo spionaggio aziendale, ma molti lo hanno provato a incolpare di omicidi o attentati vari, senza successo, le sue abilità come killer e sabotatore non hanno eguali. Il suo cuore ha retto a 47 infarti a causa dell'uso smodato di caffè che oggi potrebbe essergli fatale. Per il resto è una perfetta sintonia fra Hitman e 007",
    deadlyWeapon: "Caffè",
    subQuests: [
      "Spostare oggetti durante la serata senza un motivo logico, ma tutto fa parte del tuo grande piano",
      "Tieniti a debita distanza dalla macchinetta del caffè e dai caffè",
      "difendi il Trascendentatore se qualcuno prova ad avvicinarsi senza un apperente motivo. Serba rancore: se qualcuno ci prova è un uomo morto"
  ],
  },
];

const getById = (id) => {
  return _.find(people, (p) => p.id === id) || false;
};
export default getById;
